import React from "react"
import Layout from "../components/Layout"
import notFoundImage from "../images/404.svg"


export default () => {
    return (
        <Layout>
            <main className="w-full h-full py-6 my-24 container mx-auto">
                <a href="/" className="p-6 items-center">
                    <img src={ notFoundImage } alt="Page not found"/>
                </a>
            </main>
        </Layout>
    )
}
